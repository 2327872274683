import styled from "styled-components";

export const Section = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  background-image: url("./images/big.png");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  overflow: auto;

  @media screen and (max-width: 1000px) {
    background-image: url("./images/background.png");
    overflow: hidden;
  }
`;

export const Container = styled.div`
  position: relative;
  margin: 10rem 0 1rem 0;
  background: linear-gradient(
    45deg,
    rgba(174, 180, 160, 1) 35%,
    rgba(188, 192, 170, 1) 70%,
    rgba(234, 246, 228, 1) 100%
  );
  width: 90%;
  height: 1000rem;
  border-radius: 5vh;

  max-width: 750px;
  max-height: 692.5rem;

  @media screen and (max-width: 1000px) {
    margin: 15% 0 10% 0;
    overflow: auto;
    height: auto;
  }
`;

export const LeftContainer = styled.div`
  border-radius: 5vh;
  background: linear-gradient(
    45deg,
    rgba(5, 27, 22, 1) 30%,
    rgba(37, 63, 51, 1) 60%,
    rgba(150, 193, 163, 1) 100%
  );
`;
export const LeftContent = styled.div`
  padding: 7% 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
`;

export const TitleContainer = styled.div`
  height: 10rem;
  width: 10rem;
  display: flex;
  justify-content: center;
  background-image: url("./images/logo.png");
  background-size: cover;
  background-position: center;
`;

export const TitleText = styled.div`
  margin: 1rem 0 0 0;
  color: white;
  font-size: 2.25rem;

  font-family: "Dancing Script", cursive;
`;

export const NumberButtonContainer = styled.div`
  margin: 2.5rem 0 0 0;
  height: 2.5rem;
  width: 90%;
  max-width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0db99;
  border-radius: 6vh;
`;

export const AboutContainer = styled.div`
  margin: 1.5rem 0 1.5rem 0;
  height: 40%;
  width: 90%;
  padding: 1rem;
  text-align: center;
  color: white;
`;

export const CarouselContainer = styled.div`
  margin: 1.5rem 0 1.5rem 0;
  width: 100%;
  height: 275px;
  overflow: hidden;
`;

export const CarouselTitleContainer = styled.div`
  font-size: 2rem;
  text-align: center;
  color: white;
  font-weight: bold;
  margin: 0 0 1rem 0;
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% * ${(props) => props.cardCount});
  transition: transform 0.5s ease;
  transform: translateX(
    -${(props) => props.slideIndex * (100 / props.cardCount)}%
  );
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin: 0 1rem 0 1rem;
`;

export const FooterContainer = styled.div`
  margin: 0.5rem 0 0 0;
  padding: 1rem 0.5rem 0 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
`;

export const Address = styled.div`
  text-align: center;
`;

export const Number = styled.div`
  text-align: center;
  margin: 0.75rem 0 0 0;
`;

export const Email = styled.div`
  text-align: center;
  margin: 0.75rem 0 0 0;
`;

export const SocialMedia = styled.div`
  text-align: center;
  margin: 0.5rem 0 -1rem 0;
`;

export const CopyRight = styled.div`
  font-size: 12px;
  text-align: center;
  margin: 1rem 0 0.75rem 0;
`;

export const SocialImg = styled.img`
  height: 40px;
  width: 40px;
  cursor: pointer;
`;

export const OutBottomContain = styled.a`
  margin: 0 0 0 0;
  cursor: pointer;
  color: #000;

  @media screen and (max-width: 500px) {
    font-size: 0.85rem;
  }
`;
