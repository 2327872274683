export const homeObjOne = {
  id: "about",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "",
  headLine: "Spiritual Healing",
  description:
    "Have you ever felt just a little off and could feel something was wrong with your body or your health? And this feeling prompeted you to go to the doctor looking for answers. Only to come away from the tests and exams with a full and clear bill of health. Sometimes you would be given medicine only for the symptoms to come back again at a different time. The small nagging feeling or voice within continued to let you know something was still wrong. This inner voice is known as your spiritual guide or guides communicating with you and urging you to practice healing of your spiritual ailments. When you are disconnected from your spirit you also lose connection with the divine energy present in all life forms. This disconnection will cause an imbalance within yourself that may manifest in chronic physical, mental, and emotional illness.",
  buttonLabel: "Learn More",
  imgStart: false,
  img: "./images/img2.jpg",
  alt: "Spiritual Healing",
  dark: true,
  primary: true,
  darkText: false,
};

export const homeObjTwo = {
  id: "discover",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "",
  headLine: "What is Spiritual Healing",
  description:
    "At the core of your highest self you are inherently a powerful, wise, loving, and creative being. The presence of negative emotions and memories that have been stored within you can detrimentally affect your spiritual connection or energy flow. Spiritual healing is the process of restoring the balance between your spiritual and physical self that allows the powerful life energy to flow throughout your being. This healing works on the deepest levels within yourself as the divine energy is used to channel healing towards your mind, body, and spirit. You will need to consider spiritual healing if the pathways or energy centers present within your body become blocked or damaged. When you are blocked from this divine energy you will be out of touch with your spiritual nature. These blockages within your energy centers will manifest as chronic mental, emotional, or physical ailments. The underlying cause of these ailments are inherently a sign that you have some spiritual healing to tend to.",
  buttonLabel: "Learn More",
  imgStart: true,
  img: "./images/img10.jpg",
  alt: "?",
  dark: false,
  primary: false,
  darkText: true,
};

export const homeObjThree = {
  id: "signup",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "",
  headLine: "Types of Spiritual Healing",
  description:
    "There are many different forms and practices of spiritual healing that are available to assist you with finding harmony between your physical and spiritual energies. Some practices will focus strictly on either the physical, mental, emotional energy that may be causing the imbalance and others will take a more holistic approach focusing on your being as a whole. The type of spiritual healing you require can vary based upon identifying the area of your life that you are struggling the most in at the moment. Spiritual healing of the physical relates directly to the body while emotional healing will focus on healing the pain in your heart. Mental healing focuses on the spiritual healing of the mind as spiritual healing itself predominantly focuses on the healing of your spirit or soul. Spiritual healing for all aspects of heart, mind, body, and spirit resides in holistic healing to nurture all aspects.",
  buttonLabel: "Get started",
  imgStart: false,
  img: "./images/img3.jpg",
  alt: "?",
  dark: true,
  primary: true,
  darkText: false,
};

export const homeObjFour = {
  id: "discover",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "",
  headLine: "Chakra Healing",
  description:
    "Chakra healing works by balancing or channeling energy to the seven energy centers or chakras within the body. The seven energy centers work to emit or bring in energy on a daily basis. Chakra healing is deeply meditative practice and this form of spiritual healing works to unblock and connect the energies of the crown, solar plexus, throat, heart, sacral, root, and third eye chakras. All of these energy centers are responsible for specific physical, mental, and emotional functions throughout the body. For example a blocked throat chakra can manifest in thyroid issues, sore throats, ear infections, neck and shoulder pain or emotional imbalance such as fear of communication and a general feeling of being out of control. Additionally, a blockage of the crown chakra which is located at the top of your head can result in the physical imbalance shown through depression, an inability to learn, sensitivity to light or sound.",
  buttonLabel: "Learn More",
  imgStart: true,
  img: "./images/img7.jpg",
  alt: "?",
  dark: false,
  primary: false,
  darkText: true,
};

export const homeObjFive = {
  id: "signup",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "",
  headLine: "Crystal and Herbal Healing",
  description:
    "Spiritual healing can be achieved through the practice of crystal and herbal healing to help you overcome past life experiences as well as develop confidence and happiness for your ultimate well being. Crystals such as Amethyst are used to soothe the soul from anxiety and calm your nerves. The vibrational energy found within crystals works to raise or lower your own electromagnetic energy within in order to bring balance. Crystals can be used by themselves, paired with other crystals, or paired with herbal remedies to achieve increased energy healing.  You can use herbs such as the rose which opens the heart to love in all its forms or even rosemary which encourages sharp mental clarity and focus. You may also incorporate a combination of other spiritual healing techniques such as meditation or aromatherapy to garner their additional positive benefits.",
  buttonLabel: "Get started",
  imgStart: false,
  img: "./images/img5.jpg",
  alt: "?",
  dark: true,
  primary: true,
  darkText: false,
};

export const homeObjSix = {
  id: "discover",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "",
  headLine: "Reiki Healing",
  description:
    "A type of Japanese energy healing, reiki is a type of alternative medicine that promotes relaxation, and reduces stress through gentle physical touch.Reiki practitioners will use the palms in a hands on form of spiritual healing. The process involves the transfer of the divine energy from the practitioner or healer into the individual in need of healing. This energy transfer works to promote physical or emotional healing within the individual. As the practitioner guides the energy throughout your body you will begin to feel a release from stress and anxiety. This return of the body to its normal relaxed state allows the body to heal from the damages brought on by stress, disease, or injury. Some of the potential health benefits associated with reiki healing are improved mood, better sleep, as well as pain reduction from physical ailments.",
  buttonLabel: "Learn More",
  imgStart: true,
  img: "./images/img6.jpg",
  alt: "?",
  dark: false,
  primary: false,
  darkText: true,
};

export const homeObjSeven = {
  id: "signup",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "",
  headLine: "Ayurvedic Healing",
  description:
    "Ayurveda can be translated from the Sanskrit language to mean the knowledge of life. A knowledge to better maintain your life can be had through the spiritual healing of Ayurveda. The practice  is based upon the idea the disease is caused by an imbalance  or a stress in an individual’s consciousness. Ayurvedic healing is a type of natural medicine with origins based in India where the goal is to restore the balance between body, mind, spirit, and environment. The process involves an internal purification of self followed by herbal remedies, a special diet, yoga, massage therapy, and meditation. Plants, herbs,oils, and a variety of species play a major part in ayurvedic healing. The goal of Ayurvedic healing is to remove any impurities, reduce the presence of symptoms, increase your resistance to disease, and increase the harmony and well being of your life.",
  buttonLabel: "Get started",
  imgStart: false,
  img: "./images/img4.jpg",
  alt: "?",
  dark: true,
  primary: true,
  darkText: false,
};

export const homeObjEight = {
  id: "signup",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "",
  headLine: "Aromatherapy Healing",
  description:
    "You may utilize the effects of Aromatherapy healing to restore or remain calm within your everyday life. Aromatherapy healing is a type of spiritual healing practice using essential oils for therapeutic benefit. This practice has been used for centuries as a holistic healing treatment using natural plant oils or extracts to promote increased health and well being. How Aromatherapy healing works is that when you inhale the scent molecules of the essential oils they travel through your body’s olfactory nerves. The pathway leads the scent molecules to your brain where the amygdala or emotional center of the brain resides. Essential oils may also be absorbed through the skin where they can be massaged and utilized by your body.",
  buttonLabel: "Get started",
  imgStart: false,
  img: "./images/img1.jpg",
  alt: "?",
  dark: true,
  primary: true,
  darkText: false,
};

export const homeObjNine = {
  id: "discover",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "",
  headLine: "Meditation Healing",
  description:
    "Another form of spiritual healing can be found with the practice of meditation. Daily calm and restored balance to your body’s physical and spiritual energies can be maintained through meditation. This spiritual healing practice involves resting the mind and body in silence and space. Meditation works to clear your mind from all the racing or negative thoughts and worries it experiences throughout the day. It also encourages relaxation of the body to rid it of stress and anxiety. Meditation allows for peace and healing that is sometimes impossible to find in the modern world. It becomes difficult to give ourselves the time needed for recovery and rejuvenation. The spiritual healing from meditation involves the establishment of a different relationship between you and your thoughts. Your main focus is on your attention as you utilize meditation to practice placing your attention where and when you want. Meditation will give you the power to direct your moods and thoughts to more peaceful and productive pursuits.",
  buttonLabel: "Learn More",
  imgStart: true,
  img: "./images/img9.jpg",
  alt: "?",
  dark: false,
  primary: false,
  darkText: true,
};

export const homeObjTen = {
  id: "signup",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "",
  headLine: "Conclusion",
  description:
    "When you are disconnected from your spirit you lose connection with the divine energy present in all life forms and this imbalance may manifest in chronic physical, mental, and emotional illness that require spiritual healing. There are many different types of spiritual healing, depending on the area of your life that you are struggling with at the moment. Physical, mental, emotional, and holistic spiritual healing are all available to assist you with finding harmony between your physical and spiritual energies.",
  buttonLabel: "Get started",
  imgStart: false,
  img: "./images/img8.jpg",
  alt: "?",
  dark: true,
  primary: true,
  darkText: false,
};
