import React, { useState, useEffect } from "react";
import {
  Section,
  LeftContainer,
  LeftContent,
  Container,
  TitleContainer,
  NumberButtonContainer,
  AboutContainer,
  FooterContainer,
  Address,
  Email,
  SocialMedia,
  CopyRight,
  Number,
  CarouselContainer,
  CardContainer,
  Card,
  CarouselTitleContainer,
  TitleText,
  SocialImg,
  OutBottomContain,
} from "./styled";

import Info from "../components/Info";
import {
  homeObjOne,
  homeObjTwo,
  homeObjThree,
  homeObjFour,
  homeObjFive,
  homeObjSix,
  homeObjSeven,
  homeObjEight,
  homeObjNine,
  homeObjTen,
} from "../components/Info/data";

const Home = () => {
  const data = [
    { id: 1, title: "Robert S.", description: "Excellent service!" },
    {
      id: 2,
      title: "Jane M.",
      description: "Was very helpful, would highly recommend",
    },
    {
      id: 3,
      title: "Sarah R.",
      description: "High Quality, happy with my results!",
    },
    {
      id: 3,
      title: "Jessica S.",
      description: "Finally feel at peace. A breathe of fresh air",
    },
    {
      id: 3,
      title: "Taylor L.",
      description: "Very professional, comforting atmosphere",
    },
  ];

  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSlideIndex((prevIndex) =>
        prevIndex === data.length - 1 ? 0 : prevIndex + 1
      );
    }, 6000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Section>
      <Container>
        <LeftContainer>
          <LeftContent>
            <TitleContainer></TitleContainer>
            <TitleText>Chakra Boutique Psychic</TitleText>
            <NumberButtonContainer>
              <strong>Call for Appointment </strong>&nbsp;-&nbsp;
              <strong>(561) 572-6273</strong>
            </NumberButtonContainer>
            <AboutContainer>
              <strong>Over 15 Years Experience</strong> <br />
              <br />
              Known For My Honesty & Integrity 3rd Generation Cherokee Indian
              Spiritual Healer. I have been Helping and Guiding people for Over
              15 years. Together We will Identify whatever Blockage or Negative
              Energy that is affecting your life, and we will work together to
              overcome any Obstacle. There is NO problem too big or small I will
              help you with any and all aspects of Life.
            </AboutContainer>
            <Info {...homeObjOne} />
            <Info {...homeObjTwo} />
            <Info {...homeObjThree} />
            <Info {...homeObjFour} />
            <Info {...homeObjFive} />
            <Info {...homeObjSix} />
            <Info {...homeObjSeven} />
            <Info {...homeObjEight} />
            <Info {...homeObjNine} />
            <Info {...homeObjTen} />
          </LeftContent>
        </LeftContainer>
        <CarouselContainer>
          <CarouselTitleContainer>Reviews</CarouselTitleContainer>
          <CardContainer slideIndex={slideIndex} cardCount={data.length}>
            {data.map((card) => (
              <Card key={card.id}>
                <h2>{card.title}</h2>
                <p
                  style={{
                    margin: "1rem 0 0 0",
                    textAlign: "center",
                    fontStyle: "italic",
                  }}
                >
                  "{card.description}"
                </p>
              </Card>
            ))}
          </CardContainer>
        </CarouselContainer>
        <FooterContainer>
          <Address>905 N Federal Hwy, West Palm Beach, FL, 33403</Address>
          <Number>(561) 572-6273</Number>
          <Email>psychiccrystalenergy@yahoo.com</Email>
          <SocialMedia>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/profile.php?id=100091589628539"
            >
              <SocialImg src={"./images/facebook.png"} />
            </a>

            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/chakra_boutique_psychic?igshid=YmMyMTA2M2Y="
            >
              <SocialImg src={"./images/instagram.png"} />
            </a>
          </SocialMedia>
          <CopyRight>
            2023 Psychic Crystal Energy © All Rights Reserved
          </CopyRight>
          <OutBottomContain
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.everdarksoftware.com"
          >
            Powered By: EverDark Software
          </OutBottomContain>
        </FooterContainer>
      </Container>
    </Section>
  );
};

export default Home;
